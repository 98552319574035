import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from '@/store/user'
import $bus from '@/helpers/Events'
import $viewMode from '@/helpers/ViewMode'
import Vue3Toasity from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development' ? '/rmt' : 'https://b.vh9.ru/rmt',
  withCredentials: true
})

import 'normalize.css'
import '@/assets/fonts/index.scss'
import '@/assets/scss/app.scss'

const vueApp = createApp(App)
vueApp.config.globalProperties.$axios = axiosInstance
vueApp.config.globalProperties.$bus = $bus
vueApp.config.globalProperties.$viewMode = $viewMode

vueApp.use(router).use(Vue3Toasity).mount('#app')

store.dispatch('checkUser', { axios: axiosInstance })
