<template>
  <div class="orders-form">
    <div class="orders-form-group">
      <div class="filters" v-if="!orderNumber">
        <div class="filters-row filters-row--nowrap">
          <div
            class="filters-col"
            :class="{
              'filters-col--long': field.id === 'product_extended'
            }"
            v-for="field in productFields"
            :key="field.id"
          >
            <UiFieldset :field="field" v-model="field.value" />
          </div>
          <div class="filters-col filters-col--short">
            <ui-input
              input-type="number"
              label="Стоимость за еденицу"
              :disabled="productForm.disabled"
              :value="productForm.disabled ? productForm.price : products.price"
              v-model="products.price"
            />
          </div>
          <div class="filters-col filters-col--short">
            <ui-input
              input-type="text"
              label="Сумма"
              :disabled="true"
              :value="productForm.amount"
            />
          </div>
          <div class="filters-col filters-col--short filters-col--bottom">
            <ui-button
              label="Добавить"
              @click="addProduct()"
              :classes="['bordered-red', 'small']"
              :states="products.loading ? ['loading'] : []"
            />
          </div>
        </div>
      </div>
      <div class="orders-products" v-if="products.list.length || orderNumber">
        <div class="orders-products-label" v-if="orderNumber">
          Состав заказа
        </div>
        <div class="orders-products-row">
          <div
            class="orders-products-col"
            v-for="product in products.list"
            :key="product.id"
          >
            <OrdersProduct
              :data="product"
              :is-readonly="!!orderNumber"
              :remove-function="removeProduct"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="orders-form-group">
      <div class="filters">
        <div class="filters-row">
          <template v-for="field in fieldsByDelivery" :key="field.id">
            <div
              class="filters-col"
              :class="{
                'filters-col--full': field.id === 'post_card_text',
                'filters-col--75': [
                  'address_comment',
                  'selfpickup_shop'
                ].includes(field.id),
                'filters-col--small': [
                  'house',
                  'apartment',
                  'porch',
                  'floor'
                ].includes(field.id)
              }"
            >
              <UiFieldset :field="field" v-model="field.value" />
            </div>
            <div
              class="filters-col"
              v-if="
                ['delivery_time', 'selfpickup_shop'].includes(field.id) &&
                !$viewMode.isMobileDevice()
              "
            ></div>
          </template>
        </div>
        <div class="filters-actions">
          <template v-if="orderNumber">
            <template v-if="userRole === 'admin'">
              <template v-if="orderData.paid">
                <template v-if="orderData.deliveryType === 'self'">
                  <div class="filters-actions-item">
                    <UiButton
                      label="Передать в магазин"
                      :classes="['bordered']"
                      :states="submitLoading ? ['loading'] : []"
                      @click="setOrderState('sendToStore')"
                    />
                  </div>
                </template>
                <template v-else-if="orderState === 'sendToPrint'">
                  <div class="filters-actions-item">
                    <UiButton
                      label="Передать в доставку"
                      :classes="['bordered']"
                      :states="submitLoading ? ['loading'] : []"
                      @click="setOrderState('sendToDelivery')"
                    />
                  </div>
                </template>
              </template>
              <div class="filters-actions-item">
                <UiButton
                  label="Оформление возврата"
                  :classes="['bordered']"
                  :states="submitLoading ? ['loading'] : []"
                  @click="cancelOrder()"
                />
              </div>
            </template>
            <template v-else>
              <div class="filters-actions-item" v-if="!orderState">
                <UiButton
                  label="Принять в работу"
                  :classes="['bordered']"
                  :states="submitLoading ? ['loading'] : []"
                  @click="setOrderState('takeTheJob')"
                />
              </div>
              <div
                class="filters-actions-item"
                v-else-if="
                  [
                    'takeTheJob',
                    'assembledAndCompleted',
                    'sendToPrint'
                  ].includes(orderState)
                "
              >
                <ui-button
                  label="Принят в работу"
                  :classes="[
                    'bordered',
                    'bordered-green',
                    'bordered-green-disabled'
                  ]"
                >
                  <template #icon>
                    <inlineSvg
                      :src="
                        require('@/assets/images/icons/check-circle-icon.svg')
                      "
                    />
                  </template>
                </ui-button>
              </div>
              <div
                class="filters-actions-item"
                v-if="orderState === 'takeTheJob'"
              >
                <ui-button
                  label="Собран и укомплектован"
                  :classes="['bordered']"
                  :states="submitLoading ? ['loading'] : []"
                  @click="setOrderState('assembledAndCompleted')"
                />
              </div>
              <template
                v-else-if="
                  ['assembledAndCompleted', 'sendToPrint'].includes(orderState)
                "
              >
                <div class="filters-actions-item">
                  <ui-button
                    label="Собран и укомплектован"
                    :classes="[
                      'bordered',
                      'bordered-green',
                      'bordered-green-disabled'
                    ]"
                  >
                    <template #icon>
                      <inlineSvg
                        :src="
                          require('@/assets/images/icons/check-circle-icon.svg')
                        "
                      />
                    </template>
                  </ui-button>
                </div>
                <div class="filters-actions-item">
                  <ui-button
                    v-if="orderState === 'assembledAndCompleted'"
                    label="Отправить на печать"
                    :classes="['bordered']"
                    :states="submitLoading ? ['loading'] : []"
                    @click="setOrderState('sendToPrint')"
                  />
                  <ui-button
                    v-else
                    label="Отправлен на печать"
                    :classes="['bordered', 'bordered-green']"
                    :states="submitLoading ? ['loading'] : []"
                    @click="setOrderState('sendToPrint')"
                  >
                    <template #icon>
                      <inlineSvg
                        :src="
                          require('@/assets/images/icons/check-circle-icon.svg')
                        "
                      />
                    </template>
                  </ui-button>
                </div>
              </template>
              <div class="filters-actions-item" v-if="userRole === 'courier'">
                <UiButton
                  label="Проблемы с доставкой"
                  :classes="['bordered-red']"
                  @click.stop="
                    $bus.trigger('openModal', {
                      id: 'OrderProblemPopup',
                      orderNumber
                    })
                  "
                />
              </div>
            </template>
          </template>
          <template v-if="userRole === 'admin'">
            <div class="filters-actions-item">
              <UiButton
                :label="orderNumber ? 'Сохранить' : 'Создать заказ'"
                :classes="['red']"
                :states="submitLoading ? ['loading'] : []"
                @click="submit()"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import CollectValues from '@/helpers/CollectValues'
import UiButton from '@/components/form/UiButton'
import UiInput from '@/components/form/UiInput'
import UiFieldset from '@/components/form/UiFieldset'
import OrdersProduct from '@/components/orders/OrdersProduct'
import userStore from '@/store/user'

export default {
  components: {
    UiInput,
    UiButton,
    InlineSvg,
    UiFieldset,
    OrdersProduct
  },
  props: {
    modelValue: { type: Object, default: null },
    orderNumber: { type: String, default: null }
  },
  data() {
    return {
      fields: [],
      productFields: {
        product: {
          id: 'product_extended',
          name: 'Наименование товара',
          placeholder: 'Введите название товара или ссылку',
          type: 'select',
          searchable: true,
          allowNew: true,
          options: [],
          error: false,
          value: ''
        },
        quantity: {
          id: 'quantity',
          name: 'Количество',
          placeholder: 'Введите кол-во',
          type: 'number-input',
          error: false,
          value: ''
        }
      },
      products: {
        price: '',
        list: []
      },
      orderState: '',
      submitLoading: false
    }
  },
  computed: {
    productForm() {
      const data = {
        price: 0,
        amount: 0,
        disabled: true
      }

      const product = this.productFields.product.value
      if (product) {
        if (product.isNew) {
          data.price = ''
          data.disabled = false
          data.amount = this.products.price * this.productFields.quantity.value
        } else {
          data.price = product.price
          data.amount = product.price * this.productFields.quantity.value
        }
      }

      return data
    },
    fieldsByDelivery() {
      const deliveryField = this.fields.find(
        (field) => field.id === 'delivery_type'
      )
      if (!deliveryField) {
        return this.fields
      }

      if (deliveryField.value) {
        return this.fields.filter(
          (field) => !field.tag || field.tag === 'courier'
        )
      }

      return this.fields.filter((field) => field.tag !== 'courier')
    },
    userRole() {
      return userStore.getters.userRole
    },
    orderData() {
      const data = {
        paid: null,
        deliveryType: null
      }

      for (const field of this.fields) {
        switch (field.id) {
          case 'delivery_type':
            data.deliveryType = field.value ? 'courier' : 'self'
            break

          case 'paid':
            data.paid = field.value?.id === '1'
            break
        }
      }

      return data
    }
  },
  methods: {
    async loadFields() {
      const { data } = await this.$axios.get('/orders/form-fields', {
        params: {
          orderNumber: this.orderNumber
        }
      })

      if (typeof data.list === 'object') {
        this.fields = data.list
        this.products.list = data.products || []
        this.orderState = data.orderState || ''
        this.$emit('update:modelValue', {
          fields: this.fields,
          hasProblem: data.hasProblem
        })
      }
    },
    async submit() {
      this.submitLoading = true

      try {
        const { data } = await this.$axios.post('/orders/submit', {
          orderNumber: this.orderNumber,
          mode: this.orderNumber ? 'update' : 'create',
          fields: CollectValues(this.fields),
          products: this.products.list.map(({ id, quantity }) => {
            return {
              id,
              quantity
            }
          })
        })

        if (data.success) {
          this.$bus.trigger('showNotification', {
            type: 'success',
            message: 'Заказ создан'
          })

          this.$bus.trigger('closeModal', { id: 'addOrderModal' })
        } else {
          this.$bus.trigger('showNotification', {
            type: 'error',
            message: data.message
          })
        }
      } catch (error) {
        this.$bus.trigger('showNotification', {
          type: 'error',
          message: error.message,
          description: error.stack
        })
      }

      this.submitLoading = false
    },
    async setOrderState(mode) {
      this.submitLoading = true

      try {
        const { data } = await this.$axios.post('/orders/submit', {
          orderNumber: this.orderNumber,
          mode
        })

        if (data.success) {
          this.$bus.trigger('showNotification', {
            type: 'success',
            message: 'Статус заказа обновлён'
          })

          this.$bus.trigger('closeModal', { id: 'addOrderModal' })
        } else {
          this.$bus.trigger('showNotification', {
            type: 'error',
            message: data.message
          })
        }
      } catch (error) {
        this.$bus.trigger('showNotification', {
          type: 'error',
          message: error.message,
          description: error.stack
        })
      }

      this.submitLoading = false
    },
    cancelOrder() {
      if (!this.orderData.paid) {
        this.setOrderState('cancel')
      } else {
        this.$bus.trigger('openModal', {
          id: 'OrderBouquetDismantlingPopup',
          orderNumber: this.orderNumber
        })
      }
    },
    async addProduct() {
      const isNew = !this.productForm.disabled
      const product = this.productFields.product.value
      const quantity = this.productFields.quantity.value
      const price = isNew ? this.products.price : product.price

      if (isNew && (!product || !quantity || !price)) {
        this.$bus.trigger('showNotification', {
          type: 'warning',
          message:
            'Заполните поля "Наименование товара", "Количество" и "Стоимость за еденицу"'
        })

        return
      } else if (!product || !quantity) {
        this.$bus.trigger('showNotification', {
          type: 'warning',
          message: 'Заполните поле "Наименование товара" и "Количество"'
        })

        return
      }

      if (isNew) {
        product.id = crypto.randomUUID()
      }

      this.products.list.push({
        isNew,
        id: product.id,
        name: product.name,
        url: product.url,
        quantity,
        price,
        image: product.image
      })
    },
    removeProduct(id) {
      for (let index = 0; index < this.products.list.length; index++) {
        const element = this.products.list[index]
        if (element.id === id) {
          this.products.list.splice(index, 1)
          break
        }
      }
    }
  },
  created() {
    this.loadFields()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables';

.orders {
  &-form {
    &-group {
      padding: 20px 30px;
      border-bottom: 1px solid $color-gray;
      @media (max-width: $mobile) {
        padding: 20px;
      }
      &:last-child {
        border-bottom: 0;
      }
      .filters-row:last-child {
        margin-bottom: -20px;
        @media (max-width: $mobile) {
          margin-bottom: -10px;
        }
      }
    }
  }
  &-products {
    padding-top: 20px;
    &-label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px -20px;
      @media (max-width: $mobile) {
        margin-bottom: -5px;
      }
    }
    &-col {
      display: flex;
      flex-direction: column;
      width: 33.333%;
      padding: 0 10px;
      margin-bottom: 20px;
      @media (max-width: $mobile) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
}
</style>
