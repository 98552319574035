import { createStore } from 'vuex'
import ViewMode from '@/helpers/ViewMode'

export default createStore({
  state: {
    show: !ViewMode.isMobileDevice()
  },
  getters: {
    isVisible(state) {
      return state.show
    }
  },
  mutations: {
    toggleVisibility(state) {
      state.show = !state.show
    },
    disableVisibility(state) {
      state.show = false
    }
  },
  actions: {
    async toggleVisibility({ commit }) {
      commit('toggleVisibility')
    },
    async disableVisibility({ commit }) {
      commit('disableVisibility')
    }
  }
})
