<template>
  <div class="ui-input" :class="{ 'ui-input--error': error }">
    <label class="ui-input-label" v-if="label">
      {{ label }}
    </label>
    <div class="ui-input-error" v-if="errorMessage" v-html="errorMessage"></div>
    <div class="ui-input-field">
      <textarea
        class="ui-input-content"
        rows="3"
        :value="fieldValue"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="updateVal($event.target.value)"
        @keyup.enter="$emit('on-set')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: { default: null },
    label: { type: String, default: null },
    value: { default: null },
    modelValue: { default: null },
    placeholder: { type: String, default: null },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    errorMessage() {
      switch (this.error) {
        case 'empty-value':
          return 'Пожалуйста, заполните это поле'

        default:
          return this.error
      }
    },
    fieldValue() {
      return this.value !== null ? this.value : this.modelValue
    }
  },
  methods: {
    updateVal(val) {
      this.$emit('update:modelValue', val)
      this.$emit('on-change')
    }
  }
}
</script>
