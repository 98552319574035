class ViewMode {
  constructor() {
    this.deviceWidth = typeof window === 'object' ? window.innerWidth : 1920
  }

  isMobileDevice() {
    return this.deviceWidth < 461
  }
}

export default new ViewMode()
