<template>
  <div class="header-search">
    <div class="header-search-select">
      <div class="header-search-select-field">
        {{ searchType.name }}
        <inlineSvg
          :src="require('@/assets/images/icons/select-arrow-icon.svg')"
          class="form-select-shape"
        />
      </div>
    </div>
    <input
      class="header-search-field"
      type="text"
      v-model="searchField.value"
      :placeholder="searchField.placeholder"
    />
    <div class="header-search-button">
      <inlineSvg :src="require('@/assets/images/icons/search-icon.svg')" />
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  components: {
    InlineSvg
  },
  data() {
    return {
      searchPlace: {
        chosen: 'all',
        list: [
          {
            key: 'all',
            name: 'Везде'
          },
          {
            key: 'not-all',
            name: 'Не везде'
          }
        ]
      },
      searchField: {
        value: '',
        placeholder: 'Поиск по разделам'
      }
    }
  },
  computed: {
    searchType() {
      for (const item of this.searchPlace.list) {
        if (item.key === this.searchPlace.chosen) {
          return item
        }
      }

      return {}
    }
  }
}
</script>
