<template>
  <div class="header">
    <div class="header-left" v-if="isMobileDevice">
      <router-link to="/" class="header-logo">
        <inlineSvg :src="require('@/assets/images/layout/logo-mobile.svg')" />
      </router-link>
      <div
        class="header-menu"
        :class="{ 'header-menu--on': showSidebar }"
        @click="changeMenuState()"
      >
        <div class="header-menu-bars">
          <div
            v-for="i in 3"
            class="header-menu-bar"
            :class="'header-menu-bar--' + i++"
            :key="i"
          ></div>
        </div>
        <div class="header-menu-name">Меню</div>
      </div>
    </div>
    <PageHeaderSearch v-else />
    <div class="header-actions">
      <div class="header-button" v-if="isMobileDevice">
        <inlineSvg :src="require('@/assets/images/icons/search-icon.svg')" />
      </div>
      <div :class="classes.notifications" @click.stop="toggleNotifications()">
        <div class="header-notifications-dot" v-if="notifications.length"></div>
        <inlineSvg
          class="header-notifications-icon"
          :src="require('@/assets/images/icons/notifications-icon-empty.svg')"
        />
        <transition name="ui-modal-animation">
          <div
            class="header-notifications-list"
            @click.stop
            v-if="showNotifications && notifications.length"
          >
            <div class="header-notifications-list-title">Уведомления</div>
            <div
              class="header-notification"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div
                class="header-notification-message"
                v-html="notification.message"
              ></div>
              <div class="header-notification-date" v-if="notification.date">
                {{ notification.date }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div :class="classes.profile" @click.stop="toggleProfile()">
        <inlineSvg
          :src="require('@/assets/images/icons/profile-icon.svg')"
          class="header-profile-icon"
        />
        <template v-if="!isMobileDevice">
          <div class="header-profile-name">
            {{ userData.name }} {{ userData.secondName }}
          </div>
          <inlineSvg
            :src="require('@/assets/images/icons/select-arrow-icon.svg')"
            class="form-select-shape"
          />
        </template>
        <transition name="ui-modal-animation">
          <div class="header-profile-popup" v-if="showProfilePopup" @click.stop>
            <div class="header-profile-popup-top">
              <div class="header-profile-popup-title">
                {{ userData.name }} {{ userData.secondName }}
                {{ userData.patronymic }}
              </div>
              <div class="header-profile-popup-label">
                {{ userData.role.name }}
              </div>
            </div>
            <div class="header-profile-popup-body">
              <div class="header-profile-popup-group">
                <div class="header-profile-popup-group-label">
                  Номер телефона
                </div>
                <div class="header-profile-popup-group-text">
                  {{ userData.phone }}
                </div>
              </div>
              <div class="header-profile-popup-group">
                <div class="header-profile-popup-group-label">Логин</div>
                <div class="header-profile-popup-group-text">
                  {{ userData.login }}
                </div>
              </div>
              <div class="header-profile-popup-group">
                <div class="header-profile-popup-group-label">
                  Роль в системе
                </div>
                <div class="header-profile-popup-group-text">
                  {{ userData.systemRole }}
                </div>
              </div>
              <div class="header-profile-popup-group">
                <div class="header-profile-popup-group-label">Должность</div>
                <div class="header-profile-popup-group-text">
                  {{ userData.position }}
                </div>
              </div>
            </div>
            <div class="header-profile-popup-footer">
              <div class="header-profile-popup-logout" @click="userLogout()">
                <inlineSvg
                  :src="require('@/assets/images/icons/logout-icon.svg')"
                  class="header-profile-popup-logout-icon"
                />
                <div class="header-profile-popup-logout-title">
                  Выйти из системы
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <transition name="ui-modal-animation">
      <div
        class="page-overlay"
        v-if="!isMobileDevice && (showNotifications || showProfilePopup)"
      ></div>
    </transition>
  </div>
</template>

<script>
import userStore from '@/store/user'
import sidebarStore from '@/store/sidebar'
import PageHeaderSearch from '@/components/layout/PageHeaderSearch'
import notificationsStore from '@/store/notifications'
import InlineSvg from 'vue-inline-svg'

export default {
  components: {
    InlineSvg,
    PageHeaderSearch
  },
  data() {
    return {
      showNotifications: false,
      showProfilePopup: false
    }
  },
  methods: {
    toggleNotifications() {
      this.showNotifications = !this.showNotifications

      if (this.isMobileDevice) {
        this.closeProfile()
        sidebarStore.dispatch('disableVisibility')
      }
    },
    closeNotifications() {
      this.showNotifications = false
    },
    toggleProfile() {
      this.showProfilePopup = !this.showProfilePopup

      if (this.isMobileDevice) {
        this.closeNotifications()
        sidebarStore.dispatch('disableVisibility')
      }
    },
    closeProfile() {
      this.showProfilePopup = false
    },
    userLogout() {
      userStore.dispatch('logout')
    },
    changeMenuState() {
      sidebarStore.dispatch('toggleVisibility')
    }
  },
  computed: {
    userData() {
      return userStore.getters.user
    },
    notifications() {
      return notificationsStore.getters.list
    },
    isMobileDevice() {
      return this.$viewMode.isMobileDevice()
    },
    classes() {
      const classes = {
        profile: [],
        notifications: []
      }

      if (this.isMobileDevice) {
        classes.notifications.push('header-button')
        if (this.showNotifications) {
          classes.notifications.push('header-button--active')
        }

        classes.profile.push('header-button')
        if (this.showProfilePopup) {
          classes.profile.push('header-button--active')
        }
      } else {
        classes.notifications.push('header-notifications')
        classes.profile.push('header-profile')
      }

      return classes
    },
    showSidebar() {
      return sidebarStore.getters.isVisible
    }
  },
  mounted() {
    document.addEventListener('click', this.closeNotifications)
    document.addEventListener('click', this.closeProfile)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeNotifications)
    document.removeEventListener('click', this.closeProfile)
  }
}
</script>
