import { createStore } from 'vuex'

export default createStore({
  state: {
    list: [
      {
        id: '1',
        message:
          'Необходимо уточнить адрес доставки по заказу <b>id 64673422</b>',
        date: 'сегодня 12:10'
      },
      {
        id: '2',
        message:
          'Необходимо уточнить адрес доставки по заказу <b>id 64673422</b>',
        date: 'сегодня 12:10'
      },
      {
        id: '3',
        message:
          'Необходимо уточнить адрес доставки по заказу <b>id 64673422</b>',
        date: 'сегодня 12:10'
      },
      {
        id: '4',
        message:
          'Требуется обработка заказа <b>id 64673422</b>: проблема с получателем',
        date: 'сегодня 12:10'
      },
      {
        id: '5',
        message:
          'Необходимо уточнить адрес доставки по заказу <b>id 64673422</b>',
        date: 'сегодня 12:10'
      },
      {
        id: '6',
        message:
          'Необходимо уточнить адрес доставки по заказу <b>id 64673422</b>',
        date: 'сегодня 12:10'
      },
      {
        id: '7',
        message:
          'Необходимо уточнить адрес доставки по заказу <b>id 64673422</b>',
        date: 'сегодня 12:10'
      },
      {
        id: '8',
        message:
          'Необходимо уточнить адрес доставки по заказу <b>id 64673422</b>',
        date: 'сегодня 12:10'
      }
    ]
  },
  getters: {
    list(state) {
      return state.list
    }
  },
  mutations: {}
})
